import React, { } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { locales } from './init'
// import { useLocation, Redirect, useParams, Route, Switch } from 'react-router-dom'

const LocaleSwitch: React.FC<{ style?: string; className?: string }> = ({ style, className }) => {
  const { t:_, i18n } = useTranslation()
  const { pathname } = useLocation()

  const handleLocaleSelect = (locale: string) => {
    i18n.changeLanguage(locale)
    // localStorage.setItem('GQ_locale',locale)
  }

  return (
    <ul className={`navbar-nav nav LocaleSwitch${className ? ` ${className}` : ''}`}>

      {style === "dropdown" ? (

        <li className="nav-item dropdown" title={_('localeName')} id="basic-nav-dropdown">
          <button className="nav-link dropdown-toggle border-0 bg-transparent">{_('localeName')}</button>
          <ul className="dropdown-menu">
            {locales.map((locale) => (
              <li
                key={locale}
                className="dropdown-item"
              >
                <button
                  className={i18n.resolvedLanguage === locale ? 'disabled' : ''}
                  onClick={e => handleLocaleSelect(locale)}
                >{i18n.getFixedT(locale)('localeName')}</button>
              </li>)
            )}
          </ul>
        </li>

      ) : (

          locales.map((locale) => (
            i18n.resolvedLanguage !== locale && (
              <li key={locale} className="nav-item">
                <Link to={pathname.replace(new RegExp(`^/(?:en|cy)`), `/${locale}`)} key={locale} className="nav-link border-0 bg-transparent">
                  {i18n.getFixedT(locale)('localeName')}
                </Link>
                {/* <button key={locale} onClick={e => handleLocaleSelect(locale)} className="nav-link border-0 bg-transparent">
                  {i18n.getFixedT(locale)('localeName')}
                </button> */}
              </li>
            )
          ))

        )}
    </ul>
  )
}

export default LocaleSwitch

/**
 * Component to redirect URIs that are missing the locale.
 * @returns JSX
 */
// export const RedirectToAddMissingLocale: React.FC = () => {
//   const { i18n } = useTranslation()
//   const { pathname } = useLocation()
//   return <Redirect to={{ pathname: `/${i18n.resolvedLanguage}${pathname === '/' ? '' : pathname}` }} />
// }

/**
 * Component to update the URL when the language is switched.
 * @returns JSX
 */
// export const RedirectOnLanguageChange: React.FC = () => {
//   const { locale: urlLocale } = useParams<{ locale: 'en' | 'cy' }>()
//   const { i18n } = useTranslation()
//   const [output, setOutput] = useState<null | JSX.Element>(null)
//   const { pathname } = useLocation()

//   useEffect(() => {
//     if (i18n.resolvedLanguage !== urlLocale) {
//       setOutput(<Redirect to={{ pathname: pathname.replace(new RegExp(`^/${urlLocale}`), `/${i18n.resolvedLanguage}`) }} />)
//     }
//   }, [i18n.resolvedLanguage, pathname, urlLocale])

//   return output
// }

// export const LocaleRoutes: React.FC = ({ children }) => (<Switch>
//   <Route path='/:locale(en|cy)'>
//     <RedirectOnLanguageChange />
//     {children}
//   </Route>
//   <Route>
//     <RedirectToAddMissingLocale />
//   </Route>
// </Switch>)