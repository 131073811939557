import i18n, { LanguageDetectorModule } from "i18next"
import { initReactI18next } from "react-i18next"
// import BrowserLanguageDetector from 'i18next-browser-languagedetector'
import stringsEn from './locales/strings.en.json'
import stringsCy from './locales/strings.cy.json'

export type Locale = 'en' | 'cy'
export const locales:Locale[] = ['en', 'cy']
// const savedLocale = localStorage.getItem('GQ_locale') || 'en'

const resources = {
  en: {
    translation: stringsEn
  },
  cy: {
    translation: stringsCy
  }
}

export const DomainLanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: (services, detectorOptions, i18nextOptions) => {},
  // If the host is a subdomain of cbac.co.uk return 'cy'
  detect: () => !!window.location.host.match(/[.\w]+\.cbac\.co\.uk$/) ? 'cy' : 'en',
  cacheUserLanguage: (lng) => {}
}

i18n
  .use(initReactI18next)
  // .use(BrowserLanguageDetector)
  .use(DomainLanguageDetector)
  .init({
    resources,
    // lng: savedLocale,
    fallbackLng: 'en',
    supportedLngs: locales,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      lookupLocalStorage: 'RESOURCES_FILTER_locale',
      // order: ['querystring', 'cookie', 'locslStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    }
  })

export default i18n