// A mock function to mimic making an async request for data
// export function fetchCount(amount = 1) {
//   return new Promise<{ data: number }>((resolve) =>
//     setTimeout(() => resolve({ data: amount }), 500)
//   )
// }


// export const fetchSubject = (subjectTitle:string) => {
//   return import(`../../json/subjects/${subjectTitle.replace(/[ &/\\#,+()$~%.'":*?<>{}]/g, "-")}.json`)
// }
export const fetchSubjectFile = (subjectFile: string) => {
  return import(`../../json/${subjectFile}`)
}