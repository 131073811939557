import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useLocale } from './hooks'
import { Locale } from './init'

/**
 * To be used with React Router Dom to use the first URL param as the current locale.
 * Redirects the URL to the current locale if not matching.
 * e.g. <Route path="/en" element={<LocaleWrapper locale="en"/>}> ... Child Routes ... </Route>
 */
const LocaleWrapper: React.FC<{ locale: Locale }> = ({ locale: urlLocale }) => {
  const { i18n } = useTranslation()
  const locale = useLocale()
  const [redirect, setRedirect] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    i18n.changeLanguage(urlLocale)
    setRedirect(false)
  }, [urlLocale, i18n])

  // useEffect(() => {
  //   if (locale && locale !== urlLocale) setRedirect(true)
  // }, [locale, urlLocale])

  return redirect
    ? <Navigate to={pathname.replace(new RegExp(`^/${urlLocale}`), `/${locale}`)} />
    : <Outlet />

}

export default LocaleWrapper