import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  Routes,
  Navigate,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import './App.scss';
// import { ReactComponent as BookLogo } from './logo_book2.svg'
import { ReactComponent as WJECLogo } from './wjec.svg';
import { ReactComponent as CBACLogo } from './cbac.svg';
import { ReactComponent as EduqasLogo } from './eduqas.svg';
import { locales } from './i18n/init';
import LocaleSwitch from './i18n/LocaleSwitch';
import { useTranslation } from 'react-i18next';
import { useLocale } from './i18n/hooks';
import SubjectsIndex from './features/resources/SubjectsIndex';
import SubjectPage from './features/resources/SubjectPage';
import ResourcesIndex from './features/resources/ResourcesIndex';
import LocaleWrapper from './i18n/LocaleWrapper';
import ResourcePage from './features/resources/ResourcePage';
import { selectBrand, setSubjects, Subject, setBrand } from './features/resources/resourcesSlice';
// import { useLocalisation } from './i18n/hooks'
import { useAnalytics } from 'use-analytics';
// import _subjects from './json/wjecSubjects.json';
import { useDispatch, useSelector } from 'react-redux';

// i18n.changeLanguage('cy')
// const locale: Locale = i18n.resolvedLanguage as Locale

function App() {
  const [Logo, setLogo] = useState<React.FC<{ className?: string }>>(WJECLogo);
  const currentLocale = useLocale();
  const { t: _ } = useTranslation();
  const { page } = useAnalytics();
  const location = useLocation();
  const dispatch = useDispatch();
  const brand = useSelector(selectBrand());
  const [searchParams, setSearchParams] = useSearchParams();

  /** Fix for eduqas state / querystring / domain */
  useEffect(() => {
    if (searchParams.get('eduqas') !== null || document.location.hostname.includes('eduqas')){
      dispatch(setBrand('eduqas'))
    } else if (brand === 'eduqas' && !document.location.hostname.includes('eduqas')){
      searchParams.set('eduqas','')
      setSearchParams(searchParams, {replace: true})
    }
  }, [searchParams, dispatch, brand, setSearchParams])

  useEffect(() => {
    if(!brand) return
    import(`./json/${brand}Subjects.json`).then(({default: _subjects}) => {
      dispatch(setSubjects(_subjects[currentLocale] as Subject[]));
    })
  }, [dispatch, currentLocale, brand]);

  useEffect(() => {
    setLogo(currentLocale === 'cy' ? CBACLogo : (brand==='eduqas' ? EduqasLogo: WJECLogo ));
  }, [currentLocale, brand]);

  useEffect(() => {
    page();
    console.log(location)
  }, [page, location]);

  return (
    <div className={`App ${brand}`}>

        <header>
          <div className="navbar navbar-expand-lg navbar-dark bg-dark text-light py-3">
            <div className="container-fluid">
              <Link className="navbar-brand fs-3" to="/">
                {/* <BookLogo className="d-inline-block align-middle me-4" /> */}
                {_('Resource filter')}
                {/* <span className="fw-light">{t('Educational Resources')}</span> */}
              </Link>
              <div className="ms-auto">
                {brand!=='eduqas' && (
                  <LocaleSwitch className="d-none d-sm-inline-block locale-switch me-4" />
                )}
                <a
                  className="d-inline-block"
                  href={`https://www.${brand==='eduqas' ? 'eduqas.co.uk' : _('wjec.co.uk')}/`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Logo className="wjec-logo" />
                </a>
              </div>
            </div>
          </div>

          {brand!=='eduqas' && (
            <div className="container-fluid d-flex fw-bold">
              <LocaleSwitch className="d-sm-none locale-switch ms-auto" />
            </div>
          )}

        </header>

        <main>
          <div className="content container-fluid pt-0 pt-sm-3 pb-4">
            <Routes>
              {locales.map((locale) => (
                <Route
                  key={locale}
                  path={`/${locale}`}
                  element={<LocaleWrapper locale={locale} />}
                >
                  <Route index element={<SubjectsIndex />} />
                  <Route path=":subject" element={<SubjectPage />}>
                    <Route index element={<ResourcesIndex />} />
                    <Route
                      path="r/:resource"
                      element={<ResourcePage />}
                    ></Route>
                  </Route>
                </Route>
              ))}
              <Route path="/" element={<Navigate to={`/${currentLocale}`} />} />
            </Routes>
          </div>
        </main>

        <footer>
          <div className="bg-dark border-top py-2 text-start text-light">
            <div className="container-fluid">
              <small>
                &copy; {_('copyright')}&ndash;{new Date().getFullYear()}
              </small>
            </div>
          </div>
        </footer>
    </div>
  );
}

export default App;
