import React, { PropsWithChildren, useEffect, useState } from 'react'
import { AnalyticsProvider} from 'use-analytics'
import CookieBanner from './CookieBanner'
import analytics from './analytics'

const AnalyticsProviderWithCookieBanner:React.FC<PropsWithChildren<{}>> = ({children}) => {
    const [allowCookies, setAllowCookies] = useState<boolean>()

    useEffect(() => {
        const storedAllowCookies:string = window.localStorage.getItem('allowCookies') || ''
        switch (storedAllowCookies) {
            case 'true':
                setAllowCookies(true)
                break

            case 'false':
                setAllowCookies(false)
                break

            default:
                setAllowCookies(undefined)
        }
    }, [])

    useEffect(() => {
        if(allowCookies!==undefined){
            if(allowCookies){ 
                analytics.plugins.enable('google-analytics')
                analytics.page()
            }
            window.localStorage.setItem('allowCookies', allowCookies ? 'true' : 'false')
        }else{
            window.localStorage.removeItem('allowCookies')
        }
    }, [allowCookies])

    return <AnalyticsProvider instance={analytics}>
        <>
            {allowCookies===undefined && <CookieBanner allowCookies={allowCookies} setAllowCookies={setAllowCookies} />}
            {children}
        </>
    </AnalyticsProvider> 
    
}

export default AnalyticsProviderWithCookieBanner