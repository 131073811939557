import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useLocale } from '../../i18n/hooks'
import { selectBrand, selectSubjects, Text } from './resourcesSlice'
// import { ReactComponent as ChevronRight } from 'bootstrap-icons/icons/chevron-right.svg'
import { ReactComponent as LinkIcon } from 'bootstrap-icons/icons/link-45deg.svg'
// import SubjectIcon from './icons/chemistry'
// import { useAppDispatch } from '../../app/hooks'
// import _subjects from '../../json/subjects2.json'
import './SubjectsIndex.scss'
import SubjectIcon from './SubjectIcon'
import { CSSTransition } from 'react-transition-group' 

const SubjectsIndex: React.FC = () => {
  const subjects = useSelector(selectSubjects())
  const { t: _ } = useTranslation()
  const letters: Text = {
    en: 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z',
    cy: 'a,b,c,ch,d,dd,e,f,ff,g,ng,h,i,j,l,ll,m,n,o,p,ph,r,rh,s,t,th,u,w,y'
  }
  const locale = useLocale()
  const [showAlert, setShowAlert] = useState(true)
  const [showAlertButton, setShowAlertButton] = useState(false)
  const brand = useSelector(selectBrand());
  // const dispatch = useAppDispatch()

  // useEffect(() => {
  //   dispatch(setSubjects(_subjects[locale] as Subject[]))
  // }, [dispatch,locale])
  useEffect(()=>{
    // document.title = `${_('Subjects')} - ${_('Educational Resources')} - ${_('WJEC')}`
    document.title = `${_('Educational Resources')} - ${_('WJEC')}`
  },[locale,_])

  // useEffect(() => {
  //   dispatch(unloadSubject())
  // })
  const dismissAlert = () => setShowAlert(false)

  return <div className="SubjectsIndex container px-0 mt-4">
    {showAlertButton && <div className="text-end">
      <button className="btn btn-link mb-2" onClick={() => setShowAlert(true)}>{_("Can't find what you're looking for?")}</button>
    </div>}
    <CSSTransition
      in={showAlert} 
      timeout={200} 
      classNames="fade"
      unmountOnExit
      onEnter={() => setShowAlertButton(false)}
      onExited={() => setShowAlertButton(true)}
    >
      <div className={`alert alert-${brand==='eduqas'?'warning':'info'} alert-dismissible fade show mb-5`} role="alert">
        {/* <h5 className="alert-heading">{_("Can't find what you're looking for?")}</h5> */}
        <p className="mb-0">{_('Blended Learning Modules, Knowledge Organisers, and Exam Walk Throughs are available on these pages for the following subjects')}:</p>
        <ul className="mt-0">
          <li>{_('GCSE Core subjects')}</li>
          <li>{_('A level Core subjects')}.</li>
        </ul>
        <p className="fw-bold"><Trans>For any other subjects, please visit <a href={`https://${brand==='eduqas'?'resources.eduqas.co.uk':_('resources.wjec.co.uk')}/Pages/SearchResources.aspx`} className="" target="_blank" rel="noreferrer">Find resources</a></Trans>.</p>
        <p>{_('We’re going to be adding resources for all subjects to this area and will be updating these pages regularly - we hope that you find them useful, inspiring and engaging!')}</p>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => dismissAlert()}></button>
      </div>
    </CSSTransition>

    <h1 className="display-4 fw-bold mb-4">{_('Subjects')} <sup className="text-muted">({subjects.length})</sup></h1>

    {letters[locale].split(',').map((letter) => {
      const filtered = subjects.filter((subject) => (
        subject.resourceCount > 0
        &&
        subject.title.toLowerCase().startsWith(letter)
        &&
        (
          (letter.length > 1 || locale === 'en')
            ? true
            : (
              !subject.title.toLowerCase().startsWith(letter + letter)
              &&
              !subject.title.match(/^(?:ch|ng|ph|rh|th)/i)
            )
        )
      ))
      if (filtered.length === 0) return null
      return <div key={letter}>
        <h3 className="text-dark border-bottom display-6 fw-normal mb-3">{letter.toUpperCase()}</h3>
        <section className="letter row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 pb-3">

          {filtered.map((subject) => (
            <div key={subject.title} className="subject col mb-3">
              <Link 
                to={encodeURIComponent(subject.title).replace(/\//g,'%2F')} 
                className="btn btn-dark w-100 text-start d-flex align-items-center p-0 border-0"
              >
                <div 
                  className="icon d-block bg-primary p-2" 
                  style={{transform: 'translateX(-1px)'}}
                >
                  <SubjectIcon subject={subject.title} style={{fill: 'red'}} />
                </div>

                <div className="title text-white my-2 ps-4 pe-2 flex-fill">
                  <label >{subject.title}</label>
                </div>
                
                <div className="align-self-stretch">
                  <div className="d-flex flex-column rounded-end overflow-hidden text-dark h-100" style={{minWidth:'2.2em'}}>
                    <span className="resource-count bg-warning h-50 w-100 d-flex align-items-center justify-content-center fw-bolder">
                      {subject.resourceCount}
                    </span>
                    <span className="link-icon bg-info h-50 w-100 d-flex align-items-center">
                      <LinkIcon className="h-75 w-auto mx-auto" />
                    </span>
                  </div>
                  
                </div>
              </Link>
            </div>
          ))}

        </section>
      </div>
    })}
  </div>
}



// const SubjectsIndex2: React.FC = () => {
//   const subjects = useSelector(selectSubjects())
//   const { t: _ } = useTranslation()
//   const letters: Text = {
//     en: 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z',
//     cy: 'a,b,c,ch,d,dd,e,f,ff,g,ng,h,i,j,l,ll,m,n,o,p,ph,r,rh,s,t,th,u,w,y'
//   }
//   const locale = useLocale()
//   const dispatch = useAppDispatch()

//   useEffect(() => {
//     dispatch(setSubjects(_subjects as Subject[]))
//   }, [dispatch])

//   return <div className="HomePage">
//     <h1>{_('Subjects')}</h1>

//     {letters[locale].split(',').map((letter) => {
//       const filtered = subjects.filter((subject) => (
//         subject.resourceCount > 0
//         &&
//         subject.title[locale].toLowerCase().startsWith(letter)
//         &&
//         (
//           (letter.length > 1 || locale === 'en')
//             ? true
//             : (
//               !subject.title[locale].toLowerCase().startsWith(letter + letter)
//               &&
//               !subject.title[locale].match(/^(?:ch|ng|ph|rh|th)/i)
//             )
//         )
//       ))
//       if (filtered.length === 0) return null
//       return <div key={letter}>
//         <h3 className="text-primary border-bottom">{letter.toUpperCase()}</h3>
//         <div className="row row-cols-sm-1 row-cols-md-2 row-cols-xl-3">
//           {filtered.map((subject) => (
//             <div key={subject.id} className="col mb-3">
//               <Link to={encodeURIComponent(subject.title[locale])} className="btn btn-lg btn-secondary w-100 text-start d-flex align-items-center">
//                 {subject.title[locale]}
//                 <span className="badge bg-primary rounded-pill ms-auto me-2">{subject.resourceCount}</span>
//                 <ChevronRight />
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     })}
//   </div>
// }

export default SubjectsIndex