import { useEffect, useState } from "react"
import { 
  // TFunction, 
  useTranslation 
} from "react-i18next"
import { Locale } from "./init"

// interface LocalisationState {
//   locale: Locale,
//   strings: {
//     [key: string]: any,//ResourceBundle | string | TFunction,
//     format: TFunction,
//   },
//   t: TFunction,
// }
// interface ResourceBundle {
//   [key: string]: string
// }
// interface ResourceBundles {
//   [locale: string]: ResourceBundle,
// }
// type DispatchFn = (partial: Partial<LocalisationState>) => void
// type LocalisationHook = (bundles?: ResourceBundles, ns?: string) => [LocalisationState, DispatchFn]

export const useLocale = () => {
  const { i18n } = useTranslation()
  const [locale, setLocale] = useState<Locale>(i18n.resolvedLanguage as Locale)

  useEffect(() => {
    setLocale(i18n.resolvedLanguage as Locale)
  }, [i18n.resolvedLanguage])

  return locale
}

// export const useLocalisation: LocalisationHook = (bundles, ns) => {
//   const { t, i18n } = useTranslation()

//   const coreStrings: ResourceBundle = i18n.getResourceBundle(i18n.resolvedLanguage, 'translation')

//   let localeStrings: { [ns: string]: ResourceBundle } | ResourceBundle = {}
//   bundles && Object.entries(bundles).forEach(([locale, bundle]) => {
//     i18n.addResourceBundle(locale, ns || 'translation', bundle, ns ? false : true)
//     if (locale === i18n.resolvedLanguage) localeStrings = ns ? { [ns]: bundle } : bundle
//   })

//   const dispatch = (partialState: Partial<LocalisationState>) => {
//     if (partialState.locale) i18n.changeLanguage(partialState.locale)
//     if (partialState.strings) i18n.addResourceBundle((partialState.locale || i18n.resolvedLanguage), 'translation', partialState.strings)
//   }

//   const state: LocalisationState = {
//     locale: i18n.resolvedLanguage as Locale,
//     strings: {
//       ...coreStrings,
//       ...localeStrings,
//       format: t
//     },
//     t
//   }

//   return [
//     state,
//     dispatch
//   ]
// }

// In US spelling for backwards compatability
// export const useLocalization = useLocalisation
const defaultExport = {
  useLocale
}
export default defaultExport