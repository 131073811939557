import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, Outlet, useParams } from 'react-router-dom'
import { loadSubjectFile, selectResource, selectSubject, selectSubjects, unloadSubject } from './resourcesSlice'
// import subjects from '../../json/wjecSubjects.json'

const SubjectPage: React.FC = () => {
  const { subject: subjectTitle } = useParams()
  const { resource: resourceId } = useParams()
  const [id, setId] = useState<number>()
  const resource = useSelector(selectResource(id))
  const dispatch = useDispatch()
  const { t: _} = useTranslation()
  const subject = useSelector(selectSubject())
  const subjects = useSelector(selectSubjects())

  useEffect(() => {
    setId((resourceId&&parseInt(resourceId)) || undefined)
  }, [resourceId])

  useEffect(() => {
    if (!subjectTitle || !subjects) return
    let _subject
    _subject = subjects.find((sub) => sub.title === subjectTitle)
    if (!_subject){
      return
    }else{
      dispatch(loadSubjectFile(_subject.fileName))
      return () => {
        dispatch(unloadSubject())
      }
    }
   
  }, [subjectTitle, dispatch, subjects])

  useEffect(()=>{
    document.title = `${subject?.title} - ${_('Educational Resources')} - ${_('WJEC')}`
  },[subject?.title, _])

  return <div className="SubjectPage">
    {!resourceId && subjectTitle && subjectTitle !== _(subjectTitle, { fallbackLng: 'false' }) && (
      <Navigate to={`../${_(subjectTitle, { fallbackLng: 'false' })}`} replace={true} />
    )}
    {resourceId && subjectTitle && subjectTitle !== _(subjectTitle, { fallbackLng: 'false' }) && (
      <Navigate to={`../${_(subjectTitle, { fallbackLng: 'false' })}/r/${resourceId}`} replace={true} />
    )}
    {/* {!resourceId && subject && subject?.title !== subjectTitle && <Navigate to={`../${subject?.title}`} replace={true} />}
    {resourceId && subject && subject?.title !== subjectTitle && <Navigate to={`../${subject?.title}/r/${resourceId}`} replace={true} />} */}

    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item" aria-current="page"><Link to="../" className="text-reset">{_('Subjects')}</Link></li>
        {resource ? (<>
          <li className="breadcrumb-item" aria-current="page"><Link to="" className="text-reset">{subject?.title}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{resource?.title}</li>
        </>) : (<>
            <li className="breadcrumb-item active" aria-current="page">{subject?.title || subjectTitle}</li>
        </>)}
      </ol>
    </nav>

    {/* <Outlet/> is either <ResourceIndex/> or <ResourcePage/> depending on the URI */}
    <Outlet />
  </div>
}

export default SubjectPage