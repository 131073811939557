import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import { useLocale } from '../../i18n/hooks'
import { ReourceFile, Resource, selectBrand, selectResources, selectStatus, selectSubject, stringToFileName } from './resourcesSlice'
import { ReactComponent as Tag } from 'bootstrap-icons/icons/tag-fill.svg'
import { ReactComponent as BoxArrowUpRight } from 'bootstrap-icons/icons/box-arrow-up-right.svg'
import { ReactComponent as X } from 'bootstrap-icons/icons/x-lg.svg'
// import { ReactComponent as FilePdf } from 'bootstrap-icons/icons/file-earmark-pdf-fill.svg'
import { ReactComponent as XCircle } from 'bootstrap-icons/icons/x-circle.svg'
// import { ReactComponent as FilePpt } from 'bootstrap-icons/icons/file-earmark-ppt-fill.svg'
// import { ReactComponent as ChatSquareText } from 'bootstrap-icons/icons/chat-square-text-fill.svg'
import { ReactComponent as ChatSquareText } from 'bootstrap-icons/icons/bookmark-check-fill.svg'
import { ReactComponent as InfoCircle } from 'bootstrap-icons/icons/info-circle-fill.svg'
import { ReactComponent as QuestionCircle } from 'bootstrap-icons/icons/question-square-fill.svg'
import { ReactComponent as CardChecklist } from 'bootstrap-icons/icons/card-checklist.svg'
import { ReactComponent as BLCircles } from '../../blendedLearningCircles.svg'
import { ReactComponent as Up } from 'bootstrap-icons/icons/arrow-up.svg'
import { ReactComponent as Down } from 'bootstrap-icons/icons/arrow-down.svg'
import { ReactComponent as CheckSquare } from 'bootstrap-icons/icons/check-square-fill.svg'
import { ReactComponent as Square } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as Filter } from 'bootstrap-icons/icons/filter.svg'
import { ReactComponent as Circle } from 'bootstrap-icons/icons/circle.svg'
import { ReactComponent as CheckCircle } from 'bootstrap-icons/icons/check-circle-fill.svg'
import { ReactComponent as Caret } from 'bootstrap-icons/icons/arrow-bar-left.svg'
import { ReactComponent as ClipboardCheck } from 'bootstrap-icons/icons/clipboard-check.svg'
// import { ReactComponent as Printer } from 'bootstrap-icons/icons/printer-fill.svg'
// import { ReactComponent as Circle } from 'bootstrap-icons/icons/circle.svg'


import { useTranslation } from 'react-i18next'
import './ResourcesIndex.scss'

const ResourcesIndex: React.FC = () => {
  const resources = useSelector(selectResources())
  const status = useSelector(selectStatus())
  const [filteredResources, setFilteredResources] = useState<Resource[]>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [type, setType] = useState<string>()
  const [types, setTypes] = useState<string[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [level, setLevel] = useState<string>()
  const [cats, setCats] = useState<string[]>([])
  const [cat, setCat] = useState<string>()
  
  const locale = useLocale()
  const [sort, setSort] = useState<string>();
  const {t:_} = useTranslation()
  const subject = useSelector(selectSubject())
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const brand = useSelector(selectBrand());

  useEffect(() => {
    if (resources) {
      let filtered = [...resources]
      filtered = filtered.filter((resource) => (
        (!type || resource.type === type)
        &&
        (types.length === 0 || types.includes(resource.type))
        &&
        (tags.length === 0 || Array.from(new Set(resource.tags)).filter((rTag) => tags.find((tag)=>tag.toLocaleLowerCase()===rTag.toLocaleLowerCase())).length === tags.length)
        &&
        (!level || resource.levels.includes(level))
        &&
        // (cats.length === 0 || Array.from(new Set(resource.categories)).filter((rCat) => cats.includes(rCat)).length === cats.length) // AND
        (cats.length === 0 || Array.from(new Set(resource.categories)).filter((rCat) => cats.includes(rCat)).length > 0) // OR
        &&
        (!cat || resource.categories.includes(cat))
      ))
      if(sort) {
        filtered.sort((a,b) => {
          switch(sort){
            case 'name':
            case 'enw':
            case 'name<':
            case 'enw<':
              return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
            case 'name>':
            case 'enw>':
              return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
            case 'date':
            case 'date>':
              return new Date(a.published) > new Date(b.published) ? -1 : 1;
            case 'date<':
              return new Date(a.published) > new Date(b.published) ? 1 : -1;
            case 'level':
            case 'level<':
              return [...a.levels].sort()[0].toLowerCase() > [...b.levels].sort()[0].toLowerCase() ? 1 : -1;
            case 'level>':
              return [...a.levels].sort()[0].toLowerCase() > [...b.levels].sort()[0].toLowerCase() ? -1 : 1;
            default:
              return 0;
          }
          
        })
      }
      setFilteredResources(filtered)
    } else {
      setFilteredResources(undefined)
    }

    return () => {
      setFilteredResources(undefined)
    }
  }, [
    resources,
    type,types,
    sort,
    // tag, 
    tags,
    level,
    cat,cats,
    locale
  ])

  useEffect(() => {
    setType(searchParams.get('type') || undefined)
    setTypes(searchParams.get('types')?.split(',') || [])
    setTags(searchParams.get('tags')?.split('|') || [])
    setSort(searchParams.get('sort') || undefined)
    setLevel(searchParams.get('level') || undefined)
    setCats(searchParams.get('cats')?.split(',') || [])
    setCat(searchParams.get('cat') || undefined)
  }, [searchParams])

  const handleTypeClick = (thisType: string) => {
    const params = getCurrentParams()
    // if (thisType && thisType !== type) params.type = thisType
    // else if (params.type) delete params.type
    if (thisType) {
      const _types = params.types ? params.types.split(',') : []
      if (_types.includes(thisType)) {
        _types.splice(_types.indexOf(thisType), 1)
      } else {
        _types.push(thisType)
      }
      if (_types.length > 0) params.types = _types.join(',')
      else if (params.types) delete params.types
    }
    setSearchParams(params)
  }

  const handleTagClick = (thisTag: string) => {
    const params = getCurrentParams()

    if (thisTag) {
      const _tags = params.tags ? params.tags.split('|') : []
      if (_tags.includes(thisTag)) {
        _tags.splice(_tags.indexOf(thisTag), 1)
      } else {
        _tags.push(thisTag)
      }
      if (_tags.length > 0) params.tags = _tags.join('|')
      else if (params.tags) delete params.tags

    }
    setSearchParams(params)
  }

  const handleCatClick = (thisCat: string) => {
    // const params = getCurrentParams()
    // if (thisCat && thisCat !== cat) params.cat = thisCat
    // else if (params.cat) delete params.cat
    // setSearchParams(params)
    const params = getCurrentParams()
    if (thisCat) {
      const _cats = params.cats ? params.cats.split(',') : []
      if (_cats.includes(thisCat)) {
        _cats.splice(_cats.indexOf(thisCat), 1)
      } else {
        _cats.push(thisCat)
      }
      if (_cats.length > 0) params.cats = _cats.join(',')
      else if (params.cats) delete params.cats
    }
    setSearchParams(params)
  }

  const handleLevelClick = (thisLevel: string) => {
    const params = getCurrentParams()
    if (thisLevel && thisLevel !== level) params.level = thisLevel
    else if (params.level) delete params.level
    if(params.sort?.includes('level')) delete params.sort
    setSearchParams(params)
  }

  

  const handleClearClick = () => {
    setSearchParams({})
  }

  const getCurrentParams = () => {
    const params: { [key: string]: string } = {}
    if (tags.length > 0) params.tags = tags.join('|')
    if (type) params.type = type
    if (types.length > 0) params.types = types.join(',')
    if(sort) params.sort = sort
    if (level) params.level = level
    if (cats.length > 0) params.cats = cats.join(',')
    if (cat) params.cat = cat
    return params
  }

  const handleSortNameClick = () => {
    const params = getCurrentParams()
    switch(sort){
      case 'name':
      case 'enw':
      case 'name<':
      case 'enw<':
        params.sort = 'name>'
        break
      default:
        params.sort = 'name<'
        
    }
    setSearchParams(params)
  }

  const handleSortDateClick = () => {
    const params = getCurrentParams()
    switch (sort) {
      case 'date':
      case 'date>':
        params.sort = 'date<'
        break
      default:
        params.sort = 'date>'

    }
    setSearchParams(params)
  }

  const handleSortLevelClick = () => {
    const params = getCurrentParams()
    switch (sort) {
      case 'level':
      case 'level<':
        params.sort = 'level>'
        break
      default:
        params.sort = 'level<'

    }
    setSearchParams(params)
  }

  const stripTags = (content:string) => {
    const el = document.createElement('div')
    el.innerHTML = content
    return el.innerText
  }

  if (status === 'failed') return <div key="failed" className="alert alert-warning" role="alert">
    <h4 className="alert-heading">{_('Subject failed to load')}</h4>
    <p>{_('This Subject was not able to load in this language.')} {_('Please try switching languages or selecting another subject.')}</p>
    <Link to="/" className="btn btn-secondary">{_('Home')}</Link>
  </div>

  return <div key="resources" className="ResourcesIndex placeholder-glow">
    {<h1 className="display-4 fw-bold mb-3">
      {subject?.title || <span className="placeholder col-2"></span>}
      {!!subject?.resourceCount && !!filteredResources?.length && <sup className="text-muted">({filteredResources?.length}{filteredResources?.length !== subject?.resourceCount && `/${subject?.resourceCount}`})</sup>}
    </h1>}

    {/* {(status === 'loading' || !filteredResources) && <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>} */}

    {
    // status === 'idle' && 
    <>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">

          

          <div className="sticky-top d-none d-sm-block">
            <Filters 
              level={level}
              handleLevelClick={handleLevelClick}
              cat={cat}
              cats={cats}
              handleCatClick={handleCatClick}
              type={type}
              types={types}
              handleTypeClick={handleTypeClick}
              tags={tags}
              handleTagClick={handleTagClick}
              handleClearClick={handleClearClick}
            />
          </div>

          <div className="d-sm-none">
            <div className="text-end">
              <button className="btn btn-lg btn-light" onClick={() => setShowFilter(!showFilter)}>
                  <Caret /> {_('Filter')} <Filter />
              </button>
            </div>
            <div className={`visible offcanvas offcanvas-end${showFilter ? ' show visible' : ''}`}>
              <div className="offcanvas-header">
                {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">{_('Filter')}</h5> */}
                  <button onClick={() => setShowFilter(false)} type="button" className="ms-auto btn-close text-reset" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body pt-0">
                <Filters
                  level={level}
                  handleLevelClick={handleLevelClick}
                  cat={cat}
                  cats={cats}
                  handleCatClick={handleCatClick}
                  type={type}
                  types={types}
                  handleTypeClick={handleTypeClick}
                  tags={tags}
                  handleTagClick={handleTagClick}
                  handleClearClick={handleClearClick}
                />
              </div>
            </div>
              <div onClick={() => setShowFilter(false)} className={`offcanvas-backdrop fade${showFilter ? ' show' : ' d-none'}`}></div>
          </div>
         
        </div>
        <div className="col mt-2">

          <div className="mb-3 current-tags">
            {(type || types.length > 0 || tags.length > 0 || level || cat || cats.length>0) && (
              <button onClick={() => handleClearClick()} className="btn btn-lg btn-danger float-end mb-2">
                {_('Reset filter')}&ensp;<XCircle />
              </button>
            )}

              {tags.length === 0 && types.length === 0 && !type && !level && !cat && cats.length === 0 && (
              <span className="d-none d-sm-inline-block btn btn-lg btn-light shadow disabled me-2 tag mb-2">{_('No Tags Selected')}</span>
            )}

            {level && <button
              onClick={() => handleLevelClick(level)}
              className={`btn btn-lg btn-light shadow me-2 level mb-2`}
            >
              {_(level)}
              &ensp;<XCircle />
            </button>}

            {type && <button
              onClick={() => handleTypeClick(type)}
              className={`btn btn-lg btn-light shadow me-2 type mb-2`}
            >
              {_(type)}
              {/* {type === 'BL' && <>{_('Blended learning')}</>}
              {type === 'EWT' && <>{_('Exam Walk Through')}</>}
              {type === 'OER' && <>{_('Online Exam Review')}</>}
              {type === 'KO' && <>{_('Knowledge Organisers')}</>}
              {type === 'QB' && <>{_('Question Bank')}</>} */}
              &ensp;<XCircle />
            </button>}

            {types.sort().map((_type, index) => (
              <button
                key={_type + index}
                onClick={() => handleTypeClick(_type)}
                className="btn btn-lg btn-light shadow me-2 type mb-2"
              >
                {_(_type)}&ensp;<XCircle />
              </button>
            ))}

            {cat && <button
              onClick={() => handleCatClick(cat)}
              className={`btn btn-lg btn-light shadow me-2 cat mb-2 text-capitalize`}
            >
              {cat}

              &ensp;<XCircle />
            </button>}

            {cats.sort().map((_cat, index) => (
              <button
                key={_cat + index}
                onClick={() => handleCatClick(_cat)}
                className="btn btn-lg btn-light shadow me-2 cat mb-2 text-capitalize"
              >
                {_cat}&ensp;<XCircle />
              </button>
            ))}

            {tags.map((_tag, index) => (
              <button 
                key={_tag + index} 
                onClick={() => handleTagClick(_tag)} 
                className="btn btn-lg btn-light shadow me-2 tag mb-2"
              >
                {_tag}&ensp;<XCircle />
              </button>
            ))}

            
          </div>

          <div className="sort row row-cols-1 row-cols-md-3 row-cols-xl-3 mb-3">
            <div className="col mb-2">
                <button disabled={!filteredResources?.length} className="btn btn-lg btn-dark w-100 h-100 lh-sm" onClick={()=>handleSortNameClick()}>
                  <span>{_('Sort by Name')}</span> <em className="text-nowrap">({_('A-Z')})</em>
                &thinsp;
                {['name','enw','name<','enw<'].includes(sort||'') && <Down/>}
                {['name>', 'enw>'].includes(sort || '') && <Up />}
              </button>
            </div>
            <div className="col mb-2">
                <button disabled={!filteredResources?.length} className="btn btn-lg btn-dark w-100 h-100 lh-sm" onClick={() => handleSortDateClick()}>
                  <span>{_('Sort by Date')}</span> <em className="text-nowrap">({_('New-Old')})</em>
                &thinsp;
                {['date', 'date>'].includes(sort || '') && <Down />}
                {['date<'].includes(sort || '') && <Up />}
              </button>
            </div>
            <div className="col mb-2">
                <button disabled={!filteredResources?.length || !!level} className="btn btn-lg btn-dark w-100 h-100 lh-sm" onClick={() => handleSortLevelClick()}>
                  <span >{_('Sort by Level')}</span> <em className="text-nowrap">({_('KS', { keystage: '3' })}-{_('KS', { keystage: '5' })})</em>
              &thinsp;
              {['level', 'level<'].includes(sort || '') && <Down />}
              {['level>'].includes(sort || '') && <Up />}
              </button>
            </div>
          </div>

          {/* <div className="mb-2 fw-bold fs-5">
            {(filteredResources || []).length > 0 && <div>{(filteredResources || []).length} result(s)</div>}
            {filteredResources?.length === 0 && <div>No results</div>}
          </div> */}

          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 Resources">
              {(status === 'loading') && [1,2,3].map((i) => <div aria-hidden={true} key={`placeholder${i}`} style={{opacity:'0.7'}} className="col pb-4 Resource">
                <div className="card h-100 shadow">
                  <div
                    className="card-img-top border-bottom position-relative bg-secondary"
                    style={{
                      height: 0,
                      paddingTop: '56.25%',
                    }}
                  >
                    <div className="position-absolute top-0 left-0">
                      <small className={`d-inline-block px-2 mt-2 rounded-end shadow-sm text-white placeholder bg-primary`}>&emsp;&emsp;&emsp;&emsp;&emsp;</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title" >
                      <span className="placeholder col-10"></span>
                    </h5>
                    <div className="card-text mb-3" >
                      <span className="placeholder col-1"></span>&nbsp;
                      <span className="placeholder col-4"></span>&nbsp;
                      <span className="placeholder col-5"></span>&nbsp;
                      <span className="placeholder col-6"></span>&nbsp;
                      <span className="placeholder col-3"></span>&nbsp;
                      <span className="placeholder col-1"></span>&nbsp;
                      <span className="placeholder col-4"></span>&nbsp;
                      <span className="placeholder col-5"></span>&nbsp;
                      <span className="placeholder col-6"></span>&nbsp;
                      <span className="placeholder col-3"></span>&nbsp;
                    </div>

                    <div className="btn-group" role="group">
                      <button disabled className="btn btn-primary text-light">
                          {_('Open resource')}
                            &nbsp;
                          <BoxArrowUpRight />
                        </button>
                      <button disabled className="btn btn-light btn-more-info">{_('More info')}</button>
                    </div>


                  </div>
                  <div className="card-footer">
                     <div className="text-muted" >
                      <small>{_('Published')}: <span className="placeholder col-3"></span></small>
                    </div>
                  </div>
                </div>
              </div>
            )}
            
            {filteredResources?.map((resource: any) => {
              if (!resource) return null
              const acknowledgements = resource.files.find((file: any) => file.label?.trim() === 'Acknowledgements')
              const imgPath = (
                resource.type === 'BL' ? 
                  `${brand}/${stringToFileName(resource.subject)}-${resource.locale.toUpperCase()}/${resource.iid}` 
                :
                  (resource.type) + (resource.type === 'QB' && locale === 'cy' ? '-cy' : '')
              ) + '.jpg'

              return (
                <div key={resource.id} className="col pb-4 Resource">
                  <div className="card h-100 shadow">
                    <div 
                      className="card-img-top border-bottom position-relative bg-secondary" 
                      style={{ 
                        height: 0, 
                        paddingTop: '56.25%', 
                        backgroundImage: `
                          linear-gradient(0deg, rgba(25,25,25,.75) 0%,
                          rgba(25,25,25,0) 35%), 
                          url(${process.env.PUBLIC_URL}/img/${imgPath})
                        `,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover'
                      }}
                    >
                      
                      <Link
                        to={`r/${resource.id}`}
                        className="position-absolute top-0 left-0 w-100 h-100"
                        title="More info"
                      ></Link>
                      <div className="position-absolute top-0 left-0">
                        {resource.levels.map((level: string) => <span key={level}>
                          <small className={`bg-dark d-inline-block px-2 mt-2 rounded-end shadow-sm text-white bg-${level.toLowerCase()}`}>{_(level)}</small><br/>
                        </span>)}
                        {resource.categories.map((cat: string) => <span key={cat}>
                          <small className={`text-capitalize bg-light d-inline-block px-2 mt-2 rounded-end shadow-sm text-dark bg-${cat.replace(' ','-').toLowerCase()}`}>{cat}</small><br />
                        </span>)}

                      </div>
                      <div
                        className="text-warning position-absolute bottom-0 end-0 display-3 px-2 py-1 d-flex align-items-center"
                        // onClick={() => handleTypeClick(resource.type)}
                      >
                        {/* {resource.type === type && <span className="fs-6"><X /></span>} */}
                        {resource.type === 'KO' && <><ChatSquareText /></>}
                        {resource.type === 'EWT' && <><InfoCircle /></>}
                        {resource.type === 'QB' && <><QuestionCircle /></>}
                        {resource.type === 'OER' && <><CardChecklist /></>}
                        {resource.type === 'BL' && <BLCircles />}
                        {resource.type === 'LP' && <><ClipboardCheck/></>}
                        {resource.type === 'SP' && <><ClipboardCheck/></>}
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{resource.title}</h5>
                      <div className="card-text mb-3" >{stripTags(resource.synopsis)}</div>

                      <div className="btn-group" role="group">
                        {resource.files.length===1 && resource.files.map((file: any) => {
                          // if (file === acknowledgements) return null
                          return file !== acknowledgements && file.src && (
                            <a key={file.src} href={file.src} target="_blank" rel="noreferrer" className={`btn btn-primary btn-${brand} text-light`}>
                              {/* {file.label} */}
                              {_('Open resource')}
                              &nbsp;
                              <BoxArrowUpRight />
                              {/* {resource.type==='BL' && (
                              <a href={file.src.replace(/https?:\/\/([^/]+)\//,'https://$1/print/')} title={_('Print resource')} target="_blank" rel="noreferrer" className="btn btn-primary text-light border-start border-end">
                                <Printer/>
                                &nbsp;&nbsp;
                                <BoxArrowUpRight />
                              </a>
                            )} */}
                            </a>
                          )
                        })}
                        <Link 
                          to={`r/${resource.id}`} 
                          className="btn btn-light btn-more-info"
                        >{_('More info')}</Link>
                      </div>


                    </div>
                    <div className="card-footer">

                      <div className="text-muted" >
                        <small>{_('Published')}: {(new Date(resource.published)).toLocaleDateString()}</small>
                        <div className="float-end">
                          {resource.acknowledgements && resource.acknowledgements.length > 0 && resource.acknowledgements.map((acknowledgement: ReourceFile) => (
                            <a key={acknowledgement.src + acknowledgement.label} className="btn btn-link btn-sm" target="_blank" rel="noreferrer" href={acknowledgement.src}>{acknowledgement.label} <BoxArrowUpRight /></a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

          </div>

        </div>
      </div>
    </>}
  </div>
}

/**
 * 
 */
const Filters:React.FC<any> = ({
  level,
  cat,
  cats,
  type,
  types,
  tags,
  handleCatClick,
  handleLevelClick,
  handleTagClick,
  handleTypeClick,
  handleClearClick
}) => {
  const [allTags, setAllTags] = useState<string[]>()
  const [allTypes, setAllTypes] = useState<{ title: string, count: number }[]>()
  const [allLevels, setAllLevels] = useState<{ title: string, count: number }[]>()
  const [allCats, setAllCats] = useState<{ title: string, count: number }[]>()
  const [ignoredTags, setIgnoredTags] = useState<string[]>([])
  const locale = useLocale()
  const { t: _ } = useTranslation()
  const subject = useSelector(selectSubject())
  const resources = useSelector(selectResources())
  const status = useSelector(selectStatus())

  useEffect(() => {
    const _ignoredTags: string[] = ['gcse', 'tgau', 'as', 'a level', 'a-level', 'ug', 'safon uwch']
    const _allTags: string[] = []
    const _allTypes: { title: string, count: number }[] = []
    const _allLevels: { title: string, count: number }[] = []
    const _allCats: { title: string, count: number }[] = []
    subject?.title && _ignoredTags.push(_(subject.title).toLocaleLowerCase())
    resources.map((resource) => {
      resource.levels && resource.levels.forEach(
        (_level) => {
          let i = _allLevels.findIndex((l) => l.title === _level.trim())
          if (i === -1) {
            i = _allLevels.push({ title: _level.trim(), count: 0 }) - 1
            _ignoredTags.push(_level.trim().toLocaleLowerCase())
          }
          _allLevels[i].count++
        }
      )

      resource.tags.map((tag) => (
        tag && !_allTags.find((t) => t.toLocaleLowerCase() === tag.toLocaleLowerCase().trim()) && _allTags.push(tag.trim()))
      )

      if (resource.type) {
        let i = _allTypes.findIndex((t) => t.title === resource.type.trim())
        if (i === -1) {
          i = _allTypes.push({ title: resource.type.trim(), count: 0 }) - 1
          _ignoredTags.push(_(resource.type.trim()).toLocaleLowerCase())
        }
        _allTypes[i].count++
      }
      
      resource.categories && resource.categories.forEach(
        (_cat) => {
          let i = _allCats.findIndex((c) => c.title === _cat.trim())
          if (i === -1) {
            i = _allCats.push({ title: _cat.trim(), count: 0 }) - 1
            _ignoredTags.push(_cat.trim().toLocaleLowerCase())
          }
          _allCats[i].count++
        }
      )
      // resource.categories.map((cat) => (cat && !_allCats.includes(cat.trim()) && _allCats.push(cat.trim())))
      return resource
    })
    setAllTags(_allTags)
    setAllTypes(_allTypes)
    setAllLevels(_allLevels)
    setAllCats(_allCats)
    setIgnoredTags(_ignoredTags)

    return () => {
      setAllTags([])
      setAllTypes([])
      setAllLevels([])
      setAllCats([])
      setIgnoredTags([])
    }
  }, [resources, locale, _, subject?.title])

  useEffect(() => {
    if (!resources) return
    const levelResources = level ? resources.filter((r) => r.levels.includes(level)) : [...resources]

    let updateTypes = false
    const _allTypes = allTypes?.map((t) => {
      const count = levelResources.filter(r => r.type === t.title).length
      if(count !== t.count){
        updateTypes = true
        return { ...t, count: count }
      }
      return t
    })
    updateTypes && setAllTypes(_allTypes)

    let updateCats = false
    const _allCats = allCats?.map(c => {
      const count = levelResources.filter(r => r.categories.includes(c.title)).length
      if(count !== c.count){
        updateCats = true
        return { ...c, count: count }
      }
      return c
    })
    updateCats && setAllCats(_allCats)

  }, [level, allCats, allTypes, resources])

  return <div className={`filters pt-2`}>
    {/* <h4>Filter</h4> */}
    {(!!allLevels?.length || status === 'loading') && <div className="card shadow mb-3">
      <div className="card-body" key="level-filter">
        <h5>{_('Exam Level')}</h5>

        {status === 'loading' && [1, 2, 3].map((i) => <button
          key={`level${i}`}
          className={`w-100 btn btn-light text-start py-0 mb-2`}
        >
          <Circle />&emsp;<span className="placeholder col-4"></span> <span className="float-end placeholder col-1"></span>
        </button>)}

        {allLevels?.sort((a, b) => a.title < b.title ? -1 : 1).map((_level) => (
          <button
            key={_level.title}
            onClick={() => handleLevelClick(_level.title)}
            className={`w-100 btn btn-light text-start py-0 mb-2`}
          // style={{ fill: 'white !important' }}
          >
            {_level.title === level ? <CheckCircle /> : <Circle />}
                    &emsp;
            {_(_level.title)} <span className="float-end text-muted">{_level.count}</span>
          </button>
        ))}
      </div>
    </div>}

    {(!!allTypes?.length || status === 'loading') && <div className="card shadow mb-3">
      <div className="card-body" key="type-filter">
        <h5>{_('Resource Type')}</h5>
        {status === 'loading' && [1, 2, 3, 4].map((i) => <button
          key={`type${i}`}
          className={`w-100 btn btn-light text-start py-0 mb-2`}
        >
          <Square />&emsp;<span className="placeholder col-5"></span> <span className="float-end placeholder col-1"></span>
        </button>)}

        {allTypes?.sort((a, b) => a.title < b.title ? -1 : 1).map((_type) => (
          <button
            key={_type.title}
            onClick={() => handleTypeClick(_type.title)}
            className={`w-100 btn btn-light text-start py-0 mb-2`}
          // style={{ fill: 'white !important' }}
          >
            {/* {_type.title === type ? <CheckSquare /> : <Square />} */}
            {types?.includes(_type.title) ? <CheckSquare /> : <Square />}
                    &emsp;
            {_(_type.title)}
            {/* {_type.title === 'BL' && <>{_('Blended Learning')}</>}
                    {_type.title === 'EWT' && <>{_('Exam Walk Through')}</>}
                    {_type.title === 'OER' && <>{_('Online Exam Review')}</>}
                    {_type.title === 'KO' && <>{_('Knowledge Organisers')}</>}
                    {_type.title === 'QB' && <>{_('Question Bank')}</>} */}
            <span className="text-muted float-end">{_type.count}</span>
          </button>
        ))}
      </div>
    </div>}

    {(!!allCats?.length || status === 'loading') && <div className="card shadow mb-3">
      <div className="card-body" key="categories-filter">
        <h5>{_('Category')} / {_('Unit')}</h5>
        {status === 'loading' && Array(4).fill(null).map((u, i) => <button
          key={`cat${i}`}
          className={`w-100 btn btn-light text-start py-0 mb-2`}
        >
          <Square />&emsp;<span className="placeholder col-3"></span> <span className="float-end placeholder col-1"></span>
        </button>)}
        {allCats?.sort((a, b) => a.title < b.title ? -1 : 1).map((_cat, index) => (
          <button
            onClick={() => handleCatClick(_cat.title)}
            key={_cat.title + index}
            className={`w-100 btn btn-light text-start py-0 mb-2 text-capitalize`}
          >
            {/* {cat === _cat.title ? <CheckSquare /> : <Square />} */}
    
            {cats?.includes(_cat.title) ? <CheckSquare /> : <Square />}
                    &emsp;{_cat.title}
            <span className="text-muted float-end">{_cat.count}</span>
          </button>
        ))}
      </div>
    </div>}

    <div className="card shadow mb-3" key="tags-filter">
      <div className="card-body">
        <h5>{_('Tags')}</h5>

        {status === 'loading' && Array(10).fill(null).map((u, i) => <button
          key={`tag${i}`}
          className={`btn rounded-pill p-0 me-2 text-decoration-none btn-link`}
        >
          <Tag />&thinsp;<span className="placeholder">&emsp;&emsp;&emsp;{i % 2 === 0 && '&emsp;'}</span>
        </button>)}

        {allTags?.sort().map((_tag, index) => (
          !ignoredTags.includes(_tag.toLocaleLowerCase()) && (<button
            onClick={() => handleTagClick(_tag)}
            key={_tag + index}
            className={`btn text-start rounded-pill p-0 me-2 text-decoration-none ${tags.includes(_tag) ? 'btn-light' : 'btn-link'}`}
          >
            {!tags.includes(_tag) ? <Tag /> : <X />}
                    &thinsp;{_tag}
          </button>)
        ))}
      </div>
    </div>

    {(type || types.length > 0 || tags.length > 0 || level || cat || cats.length > 0) && (
      <button
        onClick={() => handleClearClick()}
        className="btn btn-danger float-end"
      >
        {_('Reset filter')}&ensp;<XCircle />
      </button>
    )}

  </div>
}
export default ResourcesIndex