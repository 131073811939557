import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { Locale } from '../../i18n/init'
import { 
  // fetchSubject, 
  fetchSubjectFile 
} from './resourcesAPI'
 
export interface Text {
  en: string,
  cy: string
}
export interface Subject {
  title: string
  resourceCount: number
  fileName: string
  locale: Locale
  resources?: Resource[]
}
// export interface Subject {
//   title:Text
//   link: string
//   id: string
//   resourceCount: number
//   fileName: string
// }
export interface ReourceFile {
  label: string, 
  src: string, 
  mimeType: string, 
  added: Date,
  order: number,
  category: string
}
export interface Resource {
  title: string
  link: string
  locale: Locale
  // synopsis: string
  // thumbnail: string
  levels: string[]
  id: number
  iid: number
  tags: string[]
  type: 'BL' | 'OER' | 'KO' | 'EWT' | 'QB' | 'LP' | 'SP'
  categories: string[]
  published: Date
  description: string
  // image: string
  authorIds: string[]
  subject: string
  files: ReourceFile[],
  acknowledgements: ReourceFile[],
  img: string
}
// export interface Resource {
//   title: Text
//   link: string
//   synopsis: Text
//   thumbnail: string
//   id: string
//   tags: Text[]
//   type: 'BL' | 'OER' | 'KO' | 'EWT'
//   published: Text
//   description: Text
//   image: string
//   authors: string[]
//   subjects: { title: Text, level: Text }[]
//   files: { label: Text, link: Text, category: null | Text }[]
// }
type Status = 'idle' | 'loading' | 'failed'
type Brand = 'wjec' | 'eduqas'
export interface ResourcesState {
  subjects: Subject[]
  subject?: Subject
  resources: Resource[]
  status: Status;
  brand: Brand
}

const initialState: ResourcesState = {
  subjects: [],
  subject: undefined,
  resources: [],
  status: 'idle',
  brand: document.location.hostname.includes('eduqas') ? 'eduqas' : 'wjec'
}

// export const loadSubject = createAsyncThunk(
//   'resources/fetchSubject',
//   async (title: string) => {
//     const data = await fetchSubject(title)
//     return {...data}
//   }
// )

export const stringToFileName = (string: string) => string.replace(/\?/g, '_').replace(/[= &/\\#,+()$~%'":*?<>{}]/g, "-")

export const loadSubjectFile = createAsyncThunk(
  'resources/fetchSubjectFile',
  async (file: string) => {
    // console.log('FILE', file)
    const data = await fetchSubjectFile(file)
    return { ...data, fileName:file }
  }
)

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setSubjects: (state, action: PayloadAction<Subject[]>) => {
      state.subjects = action.payload
    },
    unloadSubject: (state)=> {
      state.subject = undefined
      state.resources = []
    },
    setStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload
    },
    setBrand: (state, action: PayloadAction<Brand>) => {
      state.brand = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // .addCase(loadSubject.pending, (state) => {
      //   state.status = 'loading'
      // })
      // .addCase(loadSubject.fulfilled, (state, action:PayloadAction<Subject & {resources: Resource[]}>) => {
      //   state.status = 'idle'
      //   state.resources = action.payload.resources.filter((resource) => !!resource) || []
      // })
      .addCase(loadSubjectFile.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(loadSubjectFile.fulfilled, (state, action: PayloadAction<Subject & { resources: Resource[] }>) => {
        state.status = 'idle'
        state.resources = Object.values(action.payload.resources).filter((resource) => !!resource) || []
        state.subject = action.payload
        state.subject.resources = state.resources
      })
      .addCase(loadSubjectFile.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const { 
  setSubjects, 
  unloadSubject,
  setStatus,
  setBrand
} = resourcesSlice.actions

export const selectBrand = () => (state: RootState) => state.resources.brand
export const selectSubjects = () => (state: RootState) => state.resources.subjects
// export const selectSubjectFromTitle = (title?: string) => (state: RootState) => state.resources.subjects.find((subject) => subject.title.en === title || subject.title.cy === title)
export const selectSubjectFromTitle = (title?: string) => (state: RootState) => state.resources.subjects.find((subject) => subject.title === title)
export const selectResources = () => (state: RootState) => state.resources.resources
export const selectSubject = () => (state: RootState) => state.resources.subject
export const selectStatus = () => (state: RootState) => state.resources.status
export const selectResource = (id?:string|number) => (state: RootState) => id ? state.resources.resources.find((resource) => resource.id===id) : null

// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState())
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount))
//   }
// }

export default resourcesSlice.reducer
