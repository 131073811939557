import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

const SubjectIcon: React.FC<{ subject: string } & React.HTMLAttributes<HTMLOrSVGElement>> = ({subject, ...props}) => {
  const {i18n} = useTranslation()
  const tEn = i18n.getFixedT('en')

  const fileName = (sub:string) => {
    switch (true) {
      case sub === 'English Language/Literature':
      case sub === 'English Language & Literature':
        return 'english-literature'
      default:
        return sub.toLowerCase().replace(/[\s/]+/g,'-')
    }
  }

  const Icon = React.lazy(() => import(`./icons/${fileName(tEn(subject))}`).catch((err) => {}))

  return <Suspense fallback={<><svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"></svg></>}><Icon {...props} /></Suspense>
}

export default SubjectIcon