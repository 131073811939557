import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  ReourceFile,
  selectBrand,
  selectResource,
  stringToFileName
} from './resourcesSlice';
import { ReactComponent as Tag } from 'bootstrap-icons/icons/tag-fill.svg';
import { ReactComponent as File } from 'bootstrap-icons/icons/file-earmark.svg';
import { ReactComponent as FilePdf } from 'bootstrap-icons/icons/file-earmark-pdf-fill.svg';
import { ReactComponent as BoxArrowUpRight } from 'bootstrap-icons/icons/box-arrow-up-right.svg';
import { ReactComponent as Globe } from 'bootstrap-icons/icons/globe.svg';
import { ReactComponent as FilePpt } from 'bootstrap-icons/icons/file-earmark-ppt-fill.svg';
import { ReactComponent as FileWord } from 'bootstrap-icons/icons/file-earmark-word-fill.svg';
import { ReactComponent as FileExcel } from 'bootstrap-icons/icons/file-earmark-excel-fill.svg';
import { ReactComponent as FilePlay } from 'bootstrap-icons/icons/file-earmark-play-fill.svg';
import { ReactComponent as Printer } from 'bootstrap-icons/icons/printer.svg';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../i18n/hooks';
import { Markup } from 'interweave';

var groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const ResourcePage: React.FC = () => {
  const [id, setId] = useState<number>();
  const { resource: resourceId } = useParams();
  const resource = useSelector(selectResource(id));
  const { t: _ } = useTranslation();
  // const [files, setFiles] = useState<ReourceFile[]>()
  const [categories, setCategories] = useState<{
    [category: string]: ReourceFile[];
  }>();
  const locale = useLocale();
  const [authors, setAuthors] = useState<string[]>([]);
  const brand = useSelector(selectBrand());
  const [allAuthors, setAllAuthors] = useState<{id:string, name:string}[]>([])

  useEffect(()=>{
    if(!brand) return
    import(`../../json/${brand}Authors.json`).then(({default:authors}) => {
      setAllAuthors(authors)
    })
  }, [brand])

  useEffect(() => {
    !!resourceId && setId(parseInt(resourceId) || undefined);
  }, [resourceId]);

  useEffect(() => {
    setAuthors(
      resource?.authorIds.map(
        (id) => allAuthors.find((a) => String(a.id) === id)?.name || ''
      ) || []
    );
  }, [resource?.authorIds, allAuthors]);

  useEffect(() => {
    const _files = [...(resource?.files || [])];
    if(resource?.type === 'BL' && _files.length>0){
      _files.push({
        ..._files[0], 
        mimeType:'print', 
        label: _('Print resource'), 
        order: 1,
        src: _files[0].src.replace(/https?:\/\/([^/]+)\//,'https://$1/print/')
      })
    }
    _files?.sort((a, b) =>
      (a.category || '0') === (b.category || '0')
        ? a.order - b.order || (a.label < b.label ? -1 : 1)
        : (a.category || '0') < (b.category || '0')
        ? -1
        : 1
    );
    // setFiles(_files)
    setCategories(groupBy(_files, 'category'));
    // console.log(groupBy(_files, 'category'))
  }, [resource?.files, _, resource?.type]);

  const fileIcon = (mimeType: string) => {
    switch (mimeType) {
      case 'pdf':
        return (
          <span className="text-pdf">
            <FilePdf />
          </span>
        );
      case 'webpage':
        return (
          <span className="text-info">
            <Globe />
          </span>
        );
      case 'print':
        return (
          <span className="text-info">
            <Printer />
          </span>
        );
      case 'mspowerpoint':
        return (
          <span className="text-ppt">
            <FilePpt />
          </span>
        );
      case 'msword':
        return (
          <span className="text-word">
            <FileWord />
          </span>
        );
      case 'msexcel':
        return (
          <span className="text-excel">
            <FileExcel />
          </span>
        );
      case 'video':
        return (
          <span className="text-primary">
            <FilePlay />
          </span>
        );
      default:
        return <File />;
    }
  };

  return (
    <>
      {/* <Link to="../">Back</Link> */}
      {!resource && (
        <div className="alert alert-warning">
          {_(
            'The resource you are looking for does NOT exist in this language yet.'
          )}
          {_('We apologise for the inconvenience.')}
        </div>
      )}

      {resource && (
        <div className="mt-3 clearfix container px-0">
          <div className="row">
            <div className="col-12 col-lg-7">
              <h1>{resource?.title}</h1>
              <Markup tagName="div" content={resource.description} />

              {categories &&
                Object.keys(categories).map((category) => (
                  <div key={category}>
                    {category !== 'null' && (
                      <Markup noHtml={true} content={category} tagName="h4" />
                    )}
                    <ul className="list-unstyled">
                      {categories[category].map((file) => {
                        return (
                          <li key={file.src + file.label}>
                            <a
                              className="text-start btn btn-light btn-lg py-0"
                              href={file.src}
                              rel="noreferrer"
                              target="_blank"
                              download
                            >
                              {fileIcon(file.mimeType)} {file.label}{' '}
                              <small className="fs-6 text-muted">
                                <BoxArrowUpRight />
                              </small>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
              {/* <ul className="list-unstyled">
            {files?.map((file) => {
              return <li>
                <a className="btn btn-light btn-lg py-0" href={file.src} rel="noreferrer" target="_blank" download>
                  {fileIcon(file.mimeType)} {file.label} <small className="fs-6 text-muted"><BoxArrowUpRight /></small>{file.order} {file.category}
                </a>
              </li>
            })}
          </ul> */}
            </div>
            <div className="col">
              <div className="mb-3">
                <img
                  className="rounded bg-secondary border"
                  alt=""
                  // src={`${process.env.PUBLIC_URL}/img/${resource.type === 'BL' ? resource.img : resource.type + '.jpg'}`}
                  src={`${process.env.PUBLIC_URL}/img/${
                    (resource.type === 'BL'
                      ? `${brand}/${stringToFileName(
                          resource.subject
                        )}-${resource.locale.toUpperCase()}/${resource.iid}`
                      : resource.type +
                        (locale === 'cy' && resource.type === 'QB'
                          ? '-cy'
                          : '')) + '.jpg'
                  }`}
                />
              </div>

              <div className="mb-3">
                {resource.levels.map((level: string) => (
                  <Link
                    key={level}
                    to={`../?level=${level}`}
                    className={`text-decoration-none bg-dark d-inline-block px-3 py-1 me-2 rounded shadow-sm text-white bg-${level.toLowerCase()}`}
                  >
                    {_(level)}
                  </Link>
                ))}
                {resource.categories.map((cat: string) => (
                  <Link
                    key={cat}
                    to={`../?cat=${cat}`}
                    className={`text-decoration-none text-capitalize bg-light d-inline-block px-3 py-1 me-2 rounded shadow-sm text-dark bg-${cat
                      .replace(' ', '-')
                      .toLowerCase()}`}
                  >
                    {cat}
                  </Link>
                ))}
              </div>
              <div className="mb-3">
                <span className="fst-italic">{_('Published')}:</span>{' '}
                {new Date(resource.published).toLocaleDateString()}
                <span>
                  {!!authors?.length && (
                    <>
                      ,&ensp;
                      <span className="fst-italic">
                        {_('Authors', { count: authors.length })}:
                      </span>{' '}
                      {authors.join(', ')}
                    </>
                  )}
                </span>
              </div>

              <div className="mb-3"></div>

              <div className="tags mb-3">
                <h4>{_('Tags')}</h4>
                {resource.tags.map(
                  (_tag: string, index: number) =>
                    _tag && (
                      <Link
                        key={_tag + index}
                        className="btn rounded-pill p-0 me-2 text-decoration-none btn-link"
                        to={`../?tags=${_tag}`}
                      >
                        <Tag />
                        &thinsp;{_tag}
                      </Link>
                    )
                )}
              </div>

              {!!resource.acknowledgements.length && (
                <>
                  <h4>{_('Acknowledgements')}</h4>
                  <ul className="list-unstyled">
                    {resource.acknowledgements.map((file) => (
                      <li key={file.src + file.label}>
                        <a
                          className="btn btn-light py-0"
                          href={file.src}
                          download
                        >
                          {fileIcon(file.mimeType)} {file.label}{' '}
                          <small className="fs-6 text-muted">
                            <BoxArrowUpRight />
                          </small>
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResourcePage;
