import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const getCorrectTrackId = () => {
  const domain = document.location.hostname;
  if (domain.includes('wjec')) {
    return 'G-WVT2ZYV72W';
  } else if (domain.includes('eduqas')) {
    return 'G-79NTFZ2DJM';
  } else if (domain.includes('cbac')) {
    return 'G-TF2YWS3W1M';
  }
};

export const analytics = Analytics({
  app: 'resource-filter',
  plugins: [
    googleAnalytics({
      measurementIds: [getCorrectTrackId(),'G-R5MYZPVRP2'],
      /** disable initially, until we check the cookie status
       * @see https://getanalytics.io/conditional-loading/
       *  */
      enabled: false
    })
  ]
});

export default analytics;
