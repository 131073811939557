import React, { useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group' 
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

interface CookieBannerProps {
    allowCookies?: boolean,
    setAllowCookies: (allowCookies:boolean) => void
}
const CookieBanner:React.FC<CookieBannerProps> = ({setAllowCookies, allowCookies}) => {
    const [show, setShow] = useState(false)
    const nodeRef = useRef(null);
    const handleClick = () => {
        setShow(false)
        setAllowCookies(true)
    }
    const { t:_ } = useTranslation()

    useEffect(() => {
        setShow(allowCookies===undefined)
    }, [allowCookies])
    
    return <CSSTransition
        nodeRef={nodeRef}
        in={show} 
        timeout={200} 
        classNames="fade"
        unmountOnExit
    >
        <div ref={nodeRef} role="dialog" className='position-fixed bottom-0 w-100 bg-white p-4 border shadow-lg' style={{zIndex:1021}}>
            <div className='container'>
                <h1 className='fs-3'><>{_('Cookies and privacy')}</> </h1>
                <p><>
                    {_('We use cookies to improve user experience, and analyse website traffic.')}
                    {_('For these reasons, we may share your site usage data with our analytics partners.')}
                    {_('By clicking “Accept Cookies”, you consent to store on your device all the technologies described in our Cookie Policy.')}
                </></p>
                <p><a href={`https://www.${_('wjec.co.uk')}/home/${_('cookies')}`} target="_blank" rel="noopener noreferrer"><>{_('More information')}</> </a></p>
                <button className="btn btn-primary" onClick={e => handleClick()}><>{_('Accept cookies')}</></button>
            </div>
        </div>
    </CSSTransition>
}

export default CookieBanner